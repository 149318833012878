@import "utilities/initial-variables";
@import "utilities/functions";


$navbar-height: 1.25rem;
$family-sans-serif: 'Open Sans', sans-serif;
$link: #b79500; 
@import "bulma";


body {
  padding: 1rem 0;
  @include mobile {
    padding: 1.5rem;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Open Sans', sans-serif;

  &.title {
    font-weight: 600 !important;
  }


}
.title {
  font-size: 1.8rem;
  @include mobile {
    font-size: 1.6rem;
  }
}
.subtitle {
  font-size: 1.125rem;
  @include mobile {
    font-size: 1rem;
  }
}

.content {
  letter-spacing: -0.35px;
}
h2 a {
  color: black;
}
nav.navbar {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin-bottom: 0.5rem;
  @include mobile {
    border-top: none;
    border-bottom: none;
  }
  .navbar-brand {
    float: right;
    margin-top: -60px;
  }
  .navbar-burger span {
    @include mobile {
      color: black;
      height: 3px;

    }
  }
  .navbar-menu {
    .navbar-start {
      a.navbar-item {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        &.is-active {
          font-weight: bold;
        }
      }
    }
  }

}

@media screen and (min-width: 1088px) {
  .navbar > .container .navbar-brand, .container > .navbar .navbar-brand {
    margin-left: 0;
  }
}

.news_index, .page, .event_index, .member_index {
  padding: 2rem 1rem 1rem 1rem;

  .entry {
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    border-bottom: 1px dotted black;
  }
}
.left {
  padding-top: 2rem;
  .carousel {
    margin-bottom: 1rem;
  }
  h3.subtitle {
    margin-bottom: 0.5rem;
  }
  .contact {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 0.4rem 0;

  }
}

.content {
  clear: left;
}
.sidebar {
  padding-top: 2rem;
  border-left: 1px solid black;

  .member , .event {
    margin-bottom: 0.5rem;
    padding: 1rem 0;
    border-bottom: 1px solid black;
    h4 {
      font-size: 1.4rem;
    }
    h5 {
      font-size: 1.3rem;
    }
    h6 {
      font-size: 1.2rem;
      margin: 0 0 1.25rem 0;
      a {
        color: black;
        &:hover {
          color: $link;
        }
      }
    }    
  }
}

.front, .index {
  .system_link {
    font-size: 1.25rem;
    a {
      color: $link;
    }
  }
  .news_area {
    .news_index {
      &.entry {
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px dotted black;
      }
    }
  }

  .meetings_area {
    .meeting {
      border-top: 1px solid black;
      padding: 1rem 0 2rem 0;
      ul {
        li {
          margin-left: 0;
          list-style-type: none;
        }
      }
      h6 {
        font-size: 1.2rem;
        margin: 0;
        a {
          color: black;
          &:hover {
            color: $link;
          }
        }
      }
    }
  }
}

.caption {
  float: left;
  max-width: 60%;
  margin-bottom: 1.5rem;
  font-size: 0.85rem;
}

.credit {
  float: right;
  max-width: 37%;
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
}


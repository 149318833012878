$box-color: $text !default;
$box-background-color: $white !default;
$box-radius: $radius-large !default;
$box-shadow: 0 2px 3px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1) !default;
$box-padding: 1.25rem !default;

$box-link-hover-shadow: 0 2px 3px rgba($black, 0.1), 0 0 0 1px $link !default;
$box-link-active-shadow: inset 0 1px 2px rgba($black, 0.2), 0 0 0 1px $link !default;

.box {
  @include block;

  background-color: $box-background-color;
  border-radius: $box-radius;
  box-shadow: $box-shadow;
  color: $box-color;
  display: block;
  padding: $box-padding;
}

a.box {
  &:hover,
  &:focus {
    box-shadow: $box-link-hover-shadow;
  }

  &:active {
    box-shadow: $box-link-active-shadow;
  }
}
